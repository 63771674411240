<template>
  <section>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <div class="sub-title">
          <h3>스포츠 롤링 지급율 설정</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>단폴 롤링 지급율</th>
            <th>두폴 롤링 지급율</th>
            <th>세폴 롤링 지급율</th>
            <th>네폴 롤링 지급율</th>
            <th>옵션</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
              </div>
            </td>
          </tr>
        </table>
        <div class="sub-title">
          <h3>미니게임 롤링 지급율 설정</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>파워볼 단폴</th>
            <th>파워볼 조합</th>
            <th>파워사다리 단폴</th>
            <th>파워사다리 조합</th>
            <th>옵션</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
              </div>
            </td>
          </tr>
        </table>
        <div class="sub-title">
          <h3>E-스포츠 롤링 지급율 설정</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>단폴 롤링 지급율</th>
            <th>세폴 롤링 지급율</th>
            <th>옵션</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
              </div>
            </td>
          </tr>
        </table>
        <div class="sub-title">
          <h3>VIP 롤링 보너스</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.checkCode') }}</th>
            <th>레벨 1</th>
            <th>레벨 2</th>
            <th>레벨 3</th>
            <th>레벨 4</th>
            <th>레벨 5</th>
            <th>첫 번째 업 라인</th>
            <th>두 번째 업 라인</th>
            <th>옵션</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
              </div>
            </td>
          </tr>
        </table>
        <div class="sub-title">
          <h3>보너스 지급율 변경</h3>
        </div>
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.type') }}</th>
            <th>최대 지급율</th>
            <th>증가</th>
            <th>옵션</th>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
    <article class="page-contents-wrap">
      <div class="sub-title">
        <h3>VIP 롤링 보너스</h3>
      </div>
      <div class="page-content">
        <table class="mainTable">
          <tr>
            <th>{{ $t('table.head.checkCode') }}</th>
            <th>레벨 1</th>
            <th>레벨 2</th>
            <th>레벨 3</th>
            <th>레벨 4</th>
            <th>레벨 5</th>
          </tr>
          <tr>
            <td>슬롯-스페이드</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-PP</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-하바네로</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-월드메치</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-넷엔트</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-메버릭</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-퀵스핀</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-MG</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-플레이엔고</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-YL게이밍</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
          <tr>
            <td>슬롯-레드타이거</td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
            <td><input type="checkbox" class="set-switch"></td>
          </tr>
        </table>
        <div class="status-change-btn-wrap pt50">
          <button class="btn-innerTable btn-status-change approve" type="button">확인</button>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import TableHead from "@/components/main/table/Head.vue";
import BtnVisibleToggle from "@/components/ui/BtnVisibleToggle";
import SearchFilter from "@/components/common/SearchFilter";
import DateSelector from "@/components/common/DateSelector";
import Pagination from "@/components/common/Pagination";
import typing from "@/components/ui/Typing";
import UiSelect from "@/components/ui/Select";
import UiCheckSet from "@/components/ui/UiCheckSet";
import UiCheck from "@/components/ui/UiCheck";
export default {
  name: "EventRollPayoutEvent",
  components: {
    UiSelect,
    UiCheckSet,
    UiCheck,
    BtnVisibleToggle,
    TableHead,
    SearchFilter,
    Pagination,
    DateSelector,
    typing
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: [
          "memNick",
          "qTitle2",
          "bannerimg",
          "writedate",
          "viewcount",
          "first",
          "empty"
        ]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        memId: "",
        startDate: "",
        endDate: "",
        ip:""
      },
      srchFiltersProp: {
        selectOptions: []
      },
      dateProps:{
        start:true,
        end:false
      },
      tableData: [],
      btnText: "보기",
      memList: [],
      pageInfo: {},
      totalInfo: {},
      wlContentsActive: false
    };
  },
  methods: {
    wlContentsToggle() {
      const reverseArr = this.additionalHead.reverse();
      const addPosition = this.headInfo.dataList.indexOf("wl") + 1;
      this.wlContentsActive = !this.wlContentsActive;
      for (const item of reverseArr) {
        if (this.wlContentsActive) {
          this.headInfo.dataList.splice(addPosition, 0, item);
        } else {
          this.headInfo.dataList.splice(addPosition, 1);
        }
      }
    },
    openDetail_company() {
      // console.log(id)
    },
    // async setTableData(pageNum) {
    //     if (pageNum) {
    //     this.reqData.page = pageNum;
    //   }
    //   const data = this.reqData;
    //   const listRes = await userTracking(data);
    //   const pageInfo = listRes.data.pageInfo;
    //   this.pageInfo = pageInfo;
    //   const totalInfo = listRes.data.totalInfo;
    //   this.totalInfo = totalInfo;
    //   const memList = listRes.data.list;
    //   memList.forEach(items => {
    //     for (const item in items) {
    //       if (item === "actionDate" || item === "lastLoginDt") {
    //         items[item] = replaceDateT(items[item]);
    //       }
    //       if (!isNaN(Number(items[item]))) {
    //         items[item] = numberWithCommas(items[item]);
    //       }
    //     }
    //   });
    //   this.memList = memList;
    // }
  },
  async created() {
    // this.setTableData(1);
  }
};
</script>

<style scoped>
.table-wrapper {border: 0;}
.sub-title {
    font-size: 1em;
    font-weight: 700;
    color: var(--main);
    height: 32px;
    position: relative;
    margin-bottom: 20px;
    border-bottom: 2px solid var(--main);
}
.sub-title > h3 {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 0.9em;
    border-start-end-radius: 10px;
    border-start-start-radius: 10px;
}
.sub-title > h3::before {
    content: '●';
    color: #18698b;
    margin-right: 7px;
}
.mainTable {margin-bottom: 30px;}
.pt50 {padding-top: 50px;}
</style>
